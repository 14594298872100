.header {
  font-size: 26px;
  margin: 25px 0;
}
.more {
  font-weight: bold;
  float: right;
  font-size: 14px;
  line-height: 1.2;
  margin-top: 8px;
  color: #306bb3;
}
.more:hover {
  color: #2887e0;
}
.items {
  margin-left: -15px;
  margin-right: -15px;
}
.items:after {
  content: '';
  display: table;
  clear: both;
}
.item {
  min-height: 1px;
  float: left;
  width: 350px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
}
.date {
  color: #717171;
  font-weight: bold;
  margin-bottom: 8px;
}
.title {
  display: block;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 14px;
  color: #306bb3;
}
.title:hover {
  color: #2887e0;
}
.content {
  margin-bottom: 14px;
  line-height: 1.4;
  max-height: 56px;
  overflow: hidden;
}
.content p {
  margin: 0;
}
