.item {
  width: 12.5%;
  padding: 0 10px;
  height: 60px;
  color: #306bb3;
  display: flex;
  align-items: center;
}
.item:hover {
  color: #2887e0;
}
.icon_wrapper {
  width: 20px;
  margin-right: 20px;
  text-align: center;
}
.icon {
  display: block;
}
.text {
  font-size: 15px;
  line-height: 1.2;
  font-weight: bold;
}
.loading {
  position: relative;
}
.loading:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.5;
}
.loading:after {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  left: 50%;
  top: 50%;
  margin-left: -14px;
  margin-top: -14px;
  background: url("../../../styles/img/spin.svg");
}
