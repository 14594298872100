.wrapper
  border: 1px solid $grey200
  padding: 11px 0
//  margin-bottom: 65px

.list
  display: flex
  align-items center
  flex-wrap wrap
  //margin-bottom: -45px
  //cf()

  //&_wrapper
  //  border: 1px solid $grey200
  //  padding: 11px 0
  //  overflow: hidden

//.all_link
//  display: block
//  text-align: center
//  height: 34px
//  line-height: 34px
//  border: 1px solid $grey200
//  margin-top: -1px
//  transition: all .1s ease-out
//  cursor: pointer
//
//  &:hover
//    color: $red
