.header_link {
  font-weight: bold;
  float: right;
  font-size: 14px;
  line-height: 1.2;
  margin-top: 13px;
  color: #306bb3;
  border-bottom: 1px dashed #306bb3;
}
.header_link:hover {
  border-bottom: 1px dashed transparent;
}
