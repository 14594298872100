.header_link
  font-weight: bold
  float: right
  font-size: 14px
  line-height: 1.2
  margin-top: 13px
  color: $dark-blue
  border-bottom: 1px dashed $dark-blue

  &:hover
    border-bottom: 1px dashed transparent