.container {
  height: 18px;
}
.vertical {
  height: auto;
}
.vertical :global .ya-share2__list_direction_horizontal > .ya-share2__item {
  display: block;
  margin: 0 0 30px 0;
}
.vertical :global .ya-share2__title {
  display: none;
}
.size_l :global .ya-share2__container_size_s .ya-share2__icon {
  width: 45px;
  height: 45px;
  vertical-align: middle;
}
.size_l :global .ya-share2__container_size_s .ya-share2__counter {
  vertical-align: middle;
  font-size: 16px;
}
