//.button
//  display: inline-block
//  width: 28px
//  height: 28px
//  margin: 0 8px
//  vertical-align: middle
//  border-radius: 2px
//  border: 0
//  padding: 0
//  anim()
//
//  &:hover
//    opacity .9
//
//  &:active
//    opacity .8
//
//.vk
//  background-color: #48729e
//
//.fb
//  background-color: #3c5a98
//
//.ok
//  background-color: #ff9f4d
//
//.tw
//  background-color: #00aced

.container
  height: 18px

.vertical
  height: auto
  //text-align: center

  :global
    .ya-share2__list_direction_horizontal > .ya-share2__item
      display: block
      margin: 0 0 30px 0
    .ya-share2__title
      display: none


.size_l
  :global
    .ya-share2__container_size_s .ya-share2__icon
      width: 45px
      height: 45px
      vertical-align: middle
    .ya-share2__container_size_s .ya-share2__counter
      vertical-align: middle
      font-size: 16px
