.placeholder
  border: 1px solid $grey200
  text-align: center

  &:before
    content: ''
    display: inline-block
    vertical-align: middle
    height: 100%

.no_photo
  display: inline-block
  vertical-align: middle
  fill: $grey200