.item
  width: 12.5%
  //display: block
  //spanFluid(1, none)

  padding: 0 10px
  //padding-left: 10px
  //padding-right: 20px
  height: 60px
  //line-height: 60px
  //margin-bottom: 45px
  color: $dark-blue
  display: flex
  align-items center

  &:hover
    color: $red

.icon_wrapper
  //width: 20px
  //spanFluid(5, none)
  //height 100%
  width: 20px
  margin-right: 20px
  text-align: center

.icon
  display: block
  //vertical-align: middle

//.name
  //float: left
  //width: column-width + column-gutter * 2
  //spanFluid(7, none)
  //display: inline-block
  //vertical-align: middle
  //padding-left: 10px

.text
  //display: inline-block
  //vertical-align: middle
  font-size: 15px
  line-height: 1.2
  font-weight: bold

.loading
  position: relative

  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: #fff
    opacity: 0.5

  &:after
    content: ''
    position: absolute
    width: 28px
    height: 28px
    left: 50%
    top: 50%
    margin-left: -14px
    margin-top: -14px
    background: url(../../../styles/img/spin.svg)
