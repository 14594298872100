.header
  font-size: 26px
  margin: 25px 0

.more
  font-weight: bold
  float: right
  font-size: 14px
  line-height: 1.2
  margin-top: 8px
  color: $dark-blue

  &:hover
    color: $red

.items
  container()

.item
  span(4)
  font-size: 14px

.date
  color: $grey700
  font-weight: bold
  margin-bottom: 8px

.title
  display: block
  font-weight: bold
  font-size: 16px
  margin-bottom: 14px
  color: $dark-blue

  &:hover
    color: $red

.content
  margin-bottom: 14px
  line-height: 1.4
  max-height: 56px
  overflow: hidden
  p
    margin: 0