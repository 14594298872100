.placeholder {
  border: 1px solid #e0e0e0;
  text-align: center;
}
.placeholder:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.no_photo {
  display: inline-block;
  vertical-align: middle;
  fill: #e0e0e0;
}
